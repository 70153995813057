import React from "react";
import { graphql } from "gatsby";
import Seo from "../layout/seo";
import CardCategorie from "../components/cardCategorie";
import Layout from "../layout";
import "../styles/categoriesPage.scss";

const CategoriesPage = ({ data }) => {
  const { group } = data.allMarkdownRemark;
  return (
    <Layout>
      <Seo />
      <div
        data-component="categories-page"
        className="section columns is-centered"
      >
        <div className="column is-10">
          <ul className="block columns is-multiline">
            {group.map((e) => (
              <li key={e.fieldValue} className="column is-3">
                <CardCategorie name={e.fieldValue} content={e.nodes} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query groupByTags {
    allMarkdownRemark {
      group(field: frontmatter___Cat_gories___name) {
        nodes {
          frontmatter {
            title
          }
          id
        }
        fieldValue
      }
    }
  }
`;

export default CategoriesPage;
