import React, { useState } from "react";
import { Link } from "gatsby";
import slugify from "slugify";
import "./cardCategorie.style.scss";

const CardCategorie = ({ name, content }) => {
  const [hidden, toggleHidden] = useState(true);
  return (
    <div className="card" data-component="card-categorie">
      <header
        className={`card-header has-background-primary has-text-white ${
          hidden && "hidden"
        }`}
        aria-hidden="true"
        onClick={() => toggleHidden(!hidden)}
      >
        <p className="card-header-title has-text-white">{name}</p>
        <button className="card-header-icon" aria-label="more options">
          <span className="icon has-text-bold">{hidden ? "+" : "-"}</span>
        </button>
      </header>
      <div className={`card-content ${hidden && "is-hidden"}`}>
        <ul>
          {content.map((node) => (
            <li key={node.id}>
              <Link
                to={`/${slugify(node.frontmatter.title, {
                  strict: true,
                  lower: true,
                })}`}
              >
                <span className="has-text-weight-bold"> # . </span>{" "}
                {node.frontmatter.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CardCategorie;
